import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    clients: [],
    selectedClient: {},
    clientPhoneNumber: null,
  },
  reducers: {
    setAllClients: (state, action) => {
      state.clients = action.payload;
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setSelectedClientPhone: (state, action) => {
      state.clientPhoneNumber = action.payload;
    },
  },
});

export const { setAllClients, setSelectedClient, setSelectedClientPhone } =
  clientSlice.actions;

export const getClients = (state) => state.client.clients;
export const getSelectClient = (state) => state.client.selectedClient;
export const getSelectedClientPhone = (state) => state.client.clientPhoneNumber;

export default clientSlice.reducer;
